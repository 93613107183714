/* angular ngCloak */

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* typography */

a {
    text-decoration: none;
    /*A200*/
    color: #FF4081;
}

/* main layout */

#top-nav {
    position: fixed;
    top:0;
    width: 100%;
    min-height: 64px;
    z-index: 5;
}

#top-nav.has-open-menu {
    z-index: 100;
}

#toolbar {
    position: fixed;
    width: 100%;
    min-height: 48px;
    z-index: 5;
    /* top-nav */
    top: 64px;
    padding: 0 20px;
    background-color: rgb(255,255,255) !important;
}

#main {
    position: absolute;
    width: 100%;
    overflow: auto;
    /* 100vh - top-nav - toolbar */
    min-height: calc(100vh - 64px - 48px);
    /* top-nav + toolbar */
    top: calc(64px + 48px);
}

#content {
    position: relative;
    background-color: rgb(255,255,255) !important;
    /* 100vh - top-nav - toolbar - footer */
    min-height: calc(100vh - 64px - 48px - 64px);
}

#footer {
    position: relative;
    min-height: 64px;
    background-color: rgb(238,238,238) !important;
    color: rgb(68,68,68) !important;
}

/* xs + sm (less than 960px) */
@media (max-width: 959px) {
    #top-nav {
        min-height: 56px;
    }

    #toolbar {
        visibility: hidden;
    }

    #main {
        /* 100vh - top-nav */
        min-height: calc(100vh - 56px);
        /* top-nav */
        top: 56px;
    }

    #content {
        /* 100vh - top-nav - footer */
        min-height: calc(100vh - 56px - 56px);
    }

    #footer {
        min-height: 56px;
    }
}

/* toolbar */

.toolbar-page-title {
    padding-left: 8px;
}

.toolbar-action-buttons {
    position: fixed;
    top: 64px;
    right: 20px;
    z-index: 5;
    height: 48px;
}

.toolbar-action-buttons md-input-container {
    margin: 7px 0 !important;
}

.toolbar-fab md-icon {
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.toolbar-fab .toolbar-fab-opened {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.toolbar-fab .toolbar-fab-left {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.toolbar-fab .toolbar-fab-left.toolbar-fab-opened {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* data list */

.data-list {
    width: 100%;
    height: 353px;
    border: 1px solid #ddd;
    background-color: #eee;
    text-align: center;
    line-height: 405px;
}

.data-list .content {
    height: 100%;
    width: 100%;
    text-align: left;
    line-height: normal;
}

/* reader */

table.table-property tr td:first-child {
    width: 150px;
    font-weight: 500;
}

#reader th.date-time {
    min-width: 100px;
}

#reader th.text {
    min-width: 300px;
}

/* editor */

/* reader and editor */

.md-button.md-fab.back-to-top {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    width: 36px;
    height: 36px;
    border-radius: 0%;
}

.md-button.md-fab.back-to-top .md-ripple-container {
    border-radius: 3px;
}

.resnav {
    position: fixed;
    /* topnav + toolbar + margin */
    top: calc(64px + 48px + 10px);
    right: 20px;
    z-index: 4;
}

/* xs + sm (less than 960px) */
@media (max-width: 959px) {
    .resnav {
        /* topnav + margin */
        top: calc(48px + 10px);
    }
}

.resnav .md-button {
    min-width: 25px;
    min-height: 25px;
}

/* report */

/* sidenav */

body.side-nav-opened {
    overflow: hidden;
}

.sidenav-buttons .md-button {
    margin: 0;
    padding: 0 16px;
    height: 40px;
    text-align: left;
}

md-sidenav a:hover {
    /*600*/
    background-color: #1E88E5 !important;
}

.menu > li {
    border-bottom: 0;
}

/* home quote */

.home-quote {
    text-align: right;
    margin-top: 120px;
    padding: 3px 8px;
    border-right: 5px solid rgba(0, 0, 0, 0.6);
}

/* advanced search */

#advanced-search .group-node .group-node-header {
    cursor: hand;
    cursor: pointer;
    background-color: #eee;
    height: 48px;
    padding: 16px;
    text-transform: uppercase;
    transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
}

#advanced-search .group-node .group-node-header:hover {
    background-color: #ddd;
}

#advanced-search .group-node .group-node-header:active {
    outline: 0;
    background-color: #ccc;
}

#advanced-search .group-node.active {
    /*500*/
    border-color: #2196F3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(33, 150, 243, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(33, 150, 243, 0.6);
}

#advanced-search .group-node.active > .group-node-header md-icon,
#advanced-search .group-node.active > .group-node-header > span {
    color: #FFF;
}

#advanced-search .group-node.active > .group-node-header {
    /*500*/
    background-color: #2196F3;
    outline: 0;
}

#advanced-search .group-node .group-node-body {
    padding: 16px;
}

#advanced-search .field-node {
    display: inline-block;
    min-width: 0;
    text-transform: none;
}

/* override default material styles */

.md-button.menu-button {
    margin: 0;
    padding: 0 8px;
    height: 64px;
    border-radius: 0;
}

.md-button.menu-button.title:hover {
    background-color: transparent !important;
 }

md-content, md-tabs-wrapper {
    background-color: rgb(255,255,255) !important;
}

md-dialog {
    max-height: 95% !important;
}

md-input-container {
    margin: 12px 0 !important;
}

md-input-container.condensed {
    padding: 2px !important;
}

md-input-container .md-errors-spacer {
    display: none !important;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar) md-menu-content md-icon,
md-toolbar:not(.md-menu-toolbar) md-menu-content md-icon {
    color: rgba(0, 0, 0, 0.87);
}
md-toolbar.md-default-theme:not(.md-menu-toolbar) md-menu-content .md-button,
md-toolbar:not(.md-menu-toolbar) md-menu-content .md-button {
    color: rgba(0, 0, 0, 0.87);
}

md-menu-bar.md-default-theme md-menu._md-open > button,
md-menu-bar md-menu._md-open > button,
md-menu-bar.md-default-theme md-menu > button:focus,
md-menu-bar md-menu > button:focus {
    background-color: rgba(238, 238, 238, 0.2) !important;
}

md-menu > a.md-button.md-default-theme:not([disabled]):hover,
md-menu > a.md-button:not([disabled]):hover,
md-menu > .md-button.md-default-theme:not([disabled]):hover,
md-menu > .md-button:not([disabled]):hover {
    background-color: rgba(238, 238, 238, 0.2) !important;
}

md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent>md-icon {
    top: 4px;
}

md-menu-content.md-menu-bar-menu.md-dense .md-button {
    line-height: 30px;
}

md-dialog-actions .md-button:last-of-type {
    margin-right: 16px;
}

md-autocomplete[md-floating-label] md-input-container {
    padding-bottom: 2px;
}

md-tooltip ._md-content {
    height: auto;
}

md-fab-speed-dial.md-fab-bottom-right {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

md-fab-speed-dial {
    -webkit-transition: all .3s cubic-bezier(.55,0,.55,.2);
    transition: all .3s cubic-bezier(.55,0,.55,.2);
}

md-toast {
    position: fixed;
}

._md-toast-open-bottom md-fab-speed-dial.md-fab-bottom-left,
._md-toast-open-bottom md-fab-speed-dial.md-fab-bottom-right {
    -webkit-transition: all .4s cubic-bezier(.25,.8,.25,1);
    transition: all .4s cubic-bezier(.25,.8,.25,1);
    -webkit-transform: translate3d(0,-42px,0);
    transform: translate3d(0,-42px,0);
}

body._md-toast-animating {
    overflow: auto !important;
}

/* temporary issue workaround */

.menu-heading {
    box-sizing: border-box;
}

.md-visually-hidden {
    visibility: hidden;
}

md-autocomplete[md-floating-label] button {
    top: 0;
}

mdp-date-picker .md-button.md-icon-button,
mdp-time-picker .md-button.md-icon-button {
    margin: 10px 0 0 !important;
}

mdp-date-picker md-input-container,
mdp-time-picker md-input-container {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
}

/* hot keys override */

.cfp-hotkeys-container {
    font-size: 10px;
}

.cfp-hotkeys-close {
    min-height: 0;
    min-width: 0;
}

/* ui-grid custom style */

.ui-grid-row.ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
    /*50*/
    /*background-color: #C8E6C9;*/
    background-color: #E3F2FD;
}

.ui-grid-cell-focus {
    /*300*/
    background-color: #64B5F6;
}

.ui-grid-top-panel-background,
.ui-grid-top-panel,
.ui-grid-no-row-overlay,
.ui-grid-footer-panel-background,
.ui-grid-footer-panel {
    background: #F5F5F5;
}

.ui-grid-focuser:focus {
    /*500*/
    border-color: #2196F3;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(33, 150, 243, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(33, 150, 243, 0.6);
}

.ui-grid-cell {
    border-color: #d4d4d4;
}

.ui-grid-cell-contents-hidden + div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: 29px;
    padding: 0px 5px;
}

.ui-grid-cell-contents-hidden + div:first-child {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.ui-grid-cell.disabled {
    color: rgba(0,0,0,0.38);
}

.ui-grid-cell.new-row {
    color: rgba(0, 0, 0, 0.38);
    font-style: italic;
}
